

    import { defineComponent } from 'vue'
    // import BlockRevealAnimation from '../components/BlockRevealAnimation.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import Heading from '../components/Heading.vue'
    import LogoWall from '../components/LogoWall.vue'
    import LoopingVideo from '../components/LoopingVideo.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Projects from '../components/Projects.vue'
    import PageHeader from '../components/PageHeader.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import Slider from '../components/Slider.vue'
    import { useCms } from '../compositions/UseCms'
    import { Route } from '../enums/Route'
    import { Theme } from '../enums/Theme'
    import { useTheme } from '../compositions/UseTheme'
    import { useCarouselSizes } from '../compositions/UseCarouselSizes'
    import MetaInfo from '../components/MetaInfo.vue'
    // import Button from '../components/Button.vue'

    export default defineComponent({
        name: 'About',
        components: {
            MetaInfo,
            Heading,
            Grid,
            Column,
            Paragraph,
            Projects,
            Slider,
            LoopingVideo,
            LogoWall,
            ThemeChanger,
            // BlockRevealAnimation,
            PageHeader
            // Button
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { about } = useCms()

            const sizes = useCarouselSizes(about.value.asset_2 as any)

            return {
                Route,
                Theme,
                cms: about,
                sliderMedia: about.value.asset_2,
                sizes
            }

        }
    })

