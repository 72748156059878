import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoopingVideo = _resolveComponent("LoopingVideo")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_LogoWall = _resolveComponent("LogoWall")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Green
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "mt-32 lg:mt-64",
          header: _ctx.cms?.page_header_heading,
          "header-span": 5,
          "sub-header": _ctx.cms?.page_header_content
        }, null, 8, ["header", "sub-header"]),
        _createVNode(_component_Grid, {
          fluid: "",
          class: "mt-12 md:pb-24"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_LoopingVideo, {
                class: "about__video",
                source: _ctx.cms.asset_1.desktop_asset.permalink,
                cover: _ctx.cms.asset_1.video_cover
              }, null, 8, ["source", "cover"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Grid, { class: "py-24 md:pt-24 md:pb-48" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              span: [ 2, 4 ],
              push: [ 0, 1 ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Heading, {
                  tag: "h2",
                  innerHTML: _ctx.cms.heading_1
                }, null, 8, ["innerHTML"]),
                _createVNode(_component_Paragraph, {
                  class: "mt-32 text-gray-600",
                  innerHTML: _ctx.cms.content_1
                }, null, 8, ["innerHTML"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              span: [ 2, 6 ],
              class: "py-12 md:py-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Slider, {
                  images: _ctx.sliderMedia,
                  sizes: _ctx.sizes
                }, null, 8, ["images", "sizes"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              span: [ 2, 4 ],
              push: [ 0, 1 ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Heading, {
                  tag: "h2",
                  innerHTML: _ctx.cms.heading_2
                }, null, 8, ["innerHTML"]),
                _createVNode(_component_Paragraph, {
                  class: "mt-12 text-gray-600",
                  innerHTML: _ctx.cms.content_2
                }, null, 8, ["innerHTML"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Dark
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Grid, { class: "md:py-48" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              span: [ 2, 4 ],
              push: [ 0, 1 ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Heading, {
                  tag: "h2",
                  innerHTML: _ctx.cms.heading_3
                }, null, 8, ["innerHTML"]),
                _createVNode(_component_Paragraph, {
                  class: "mt-12 text-gray-600",
                  innerHTML: _ctx.cms.content_3
                }, null, 8, ["innerHTML"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_Grid, { class: "sm:mt-16" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { span: [ 2, 6 ] }, {
              default: _withCtx(() => [
                _createVNode(_component_Paragraph, { heading: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.cms.related_clients_label), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_LogoWall, { class: "mt-20" })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.cms?.related_cases_heading)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 0,
              class: "mt-24 md:mt-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Paragraph, {
                      innerHTML: _ctx.cms?.related_cases_heading,
                      heading: ""
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.cms?.related_cases)
          ? (_openBlock(), _createBlock(_component_Projects, {
              key: _ctx.cms?.heading,
              class: "sm:mt-12 md:mt-16",
              data: _ctx.cms?.related_cases
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data
        }, null, 8, ["seo", "structured-data"]))
      : _createCommentVNode("", true)
  ], 64))
}